<template>
    <v-card class="mt-2 ml-2 mr-2">
        <FJenisKartuTable class="pl-2 pr-2" ref="refJenisKartuTable"/>
    </v-card>
  </template>
  
  <script>
  import FJenisKartuTable from "@/components/admin_setup/jenis_kartu/FJenisKartuTable";
  
  export default {
    name: "JenisKartu",
    components: {
      FJenisKartuTable
    },
    data() {
      return {
        tabTitle: 'JenisKartu'
      }
    },
    computed: {
    },
    methods: {
      tabJenisKartuClick(){
        this.$refs.refJenisKartuTable.fetchParent()
      },
  
    },
  
  
  }
  </script>
  
  <style scoped>
  </style>